import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { BusinessUnitEvaluatorPageComponent } from './pages/business-unit-evaluator-page/business-unit-evaluator-page.component';
import { NavbarModule } from '../../shared/features/navbar/navbar.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { LayoutModule } from '../../shared/features/layout/layout.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { PaginationModule } from '../../shared/features/pagination/pagination.module';
import { CaptionModule } from '../../shared/features/caption/caption.module';
import { DocumentationModule } from '../../shared/features/documentation/documentation.module';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzInputModule } from 'ng-zorro-antd/input';
import { SearchInputModule } from 'src/app/shared/features/search-input/search-input.module';
import { AccessRightsModule } from 'src/app/shared/features/access-rights/access-rights.module';
import { BusinessUnitEvaluatorRoutingModule } from './business-unit-evaluator.routing.module';
import { SolutionsToEvaluateComponent } from './components/solutions-to-evaluate/solutions-to-evaluate.component';
import { EvaluatedSolutionsComponent } from './components/evaluated-solutions/evaluated-solutions.component';
import { DataTableModule } from 'src/app/shared/features/data-table/data-table.module';
import { SolutionsTableComponent } from './components/solutions-table/solutions-table.component';
import {
  VeoliaTableComponent,
  VeoliaTableColumnComponent,
  VeoliaTableHeaderComponent,
  VeoliaTableCellComponent,
  VeoliaTableActionComponent,
} from '@veolia.com/vds-angular-components/table';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { VeoliaChipComponent } from '@veolia.com/vds-angular-components/chips';
import { VeoliaDropdownComponent } from '@veolia.com/vds-angular-components/dropdown';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { VeoliaPageHeaderComponent } from '@veolia.com/vds-angular-components/page-header';
import { VeoliaBreadcrumbComponent } from '@veolia.com/vds-angular-components/breadcrumb';
import { VeoliaSwitchComponent } from '@veolia.com/vds-angular-components/switch';
import { SolutionShareComponent } from 'src/app/shared/features/solution-share/components/solution-share.component';
import { SolutionInfoSummaryModule } from 'src/app/shared/features/solution-info-summary/solution-info-summary.module';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { SecurityScoreCardComponent } from 'src/app/shared/features/security-score-card/security-score-card.component';
import { SecurityScoreCardApi } from 'src/app/core/apis/security-score-card.api';
import { SecurityScoreCardService } from 'src/app/core/services/security-score-card.service';
import { SimpleSpinnerModule } from '../../shared/features/simple-spinner/simple-spinner.module';
import { ExportCSVButtonComponent } from 'src/app/shared/features/export-csv-button/export-csv-button.component';

@NgModule({
  declarations: [
    BusinessUnitEvaluatorPageComponent,
    SolutionsTableComponent,
    SolutionsToEvaluateComponent,
    EvaluatedSolutionsComponent,
  ],
  imports: [
    CommonModule,
    VeoliaModalModule,
    DataTableModule,
    LayoutModule,
    NzTableModule,
    NzIconModule,
    NavbarModule,
    NzButtonModule,
    NzTabsModule,
    NzDropDownModule,
    NzToolTipModule,
    NzMenuModule,
    NzAlertModule,
    NzCheckboxModule,
    NzPaginationModule,
    NzTypographyModule,
    NzSelectModule,
    NzInputModule,
    PaginationModule,
    CaptionModule,
    DocumentationModule,
    SearchInputModule,
    AccessRightsModule,
    VeoliaTableComponent,
    VeoliaTableColumnComponent,
    VeoliaTableHeaderComponent,
    VeoliaTableCellComponent,
    VeoliaTableActionComponent,
    VeoliaTagComponent,
    VeoliaIconComponent,
    VeoliaChipComponent,
    VeoliaDropdownComponent,
    VeoliaLinkComponent,
    VeoliaPageHeaderComponent,
    VeoliaBreadcrumbComponent,
    VeoliaSwitchComponent,
    SolutionShareComponent,
    SolutionInfoSummaryModule,
    SecurityScoreCardComponent,
    BusinessUnitEvaluatorRoutingModule,
    SimpleSpinnerModule,
    ExportCSVButtonComponent,
  ],
  providers: [SecurityScoreCardApi, SecurityScoreCardService],
})
export class BusinessUnitEvaluatorModule {}
