import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FieldWrapper,
  FormlyFieldConfig,
  FormlyFieldProps as CoreFormlyFieldProps,
  FormlyModule,
} from '@ngx-formly/core';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

export interface FormlyFieldProps extends CoreFormlyFieldProps {
  hideRequiredMarker?: boolean;
  hideLabel?: boolean;
  deprecated?: boolean;
}

@Component({
  standalone: true,
  selector: 'app-form-deprecated-wrapper-field',
  template: `
    <nz-form-item *ngIf="value && value !== ''">
      <ng-container *ngIf="props.label && props.hideLabel !== true">
        <nz-form-label
          [nzRequired]="props.required && props.hideRequiredMarker !== true"
          [nzFor]="id">
          {{ props.label }}
          {{ ' ' }}
          <div
            class="row-no-gap"
            nz-tooltip
            nzTooltipTitle="FYI, following the redesign of the form, this question is deprecated and it is no longer possible to answer.">
            {{ ' ' }}
            <span nz-typography nzType="danger"> {{ ' ' }} (Deprecated) </span>
            <veolia-icon
              [size]="16"
              [filled]="false"
              color="#ff4d4f"
              [name]="'info'"></veolia-icon>
          </div>
        </nz-form-label>
      </ng-container>
      <nz-form-control
        [nzValidateStatus]="errorState"
        [nzErrorTip]="errorTpl"
        [nzExtra]="props.description">
        <ng-container #fieldComponent></ng-container>
        <ng-template #errorTpl let-control>
          <formly-validation-message
            [field]="field"></formly-validation-message>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .row-no-gap {
        margin-left: 5px;
      }
    `,
  ],
  imports: [
    CommonModule,
    FormlyModule,
    NzFormModule,
    NzTypographyModule,
    VeoliaIconComponent,
    NzToolTipModule,
  ],
})
export class FormWrapperDeprecatedFieldComponent extends FieldWrapper<
  FormlyFieldConfig<FormlyFieldProps>
> {
  get value() {
    return this.field?.formControl?.value;
  }

  get errorState() {
    return this.showError ? 'error' : '';
  }
}
