import { Solution } from '../../models/solution';
import { NzTableSortOrder } from 'ng-zorro-antd/table';

export interface SolutionItemStoreState<T> {
  filter: {
    key: string;
    value: NzTableSortOrder;
  };
  currentPage: number;
  isLoading: boolean;
  isSearching: boolean;
  error: string | null;
  solutions: Solution[];
  total: number;
  limit: number;
  solutionType: number;
  businessUnit: string;
  isForward: boolean;
  prevCursor: any;
  nextCursor: any;
  search: string;
  uid: string;
  email: string;
  evaluatorBusinessUnits: string[];
}

export const solutionItemStoreInitialState = {
  filter: {
    key: 'solutionName',
    value: 'ascend',
  },
  currentPage: 1,
  isLoading: false,
  isSearching: false,
  error: null,
  solutions: [],
  total: 0,
  limit: 10,
  solutionType: 0,
  businessUnit: '',
  isForward: true,
  prevCursor: null,
  nextCursor: null,
  search: '',
  uid: '',
  email: '',
  evaluatorBusinessUnits: [],
};
