import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appLowercase]',
  standalone: true,
})
export class LowercaseInputDirective {
  private lastValue = '';

  constructor(private ref: ElementRef<HTMLInputElement>) {}

  @HostListener('input', ['$event'])
  @HostListener('paste', ['$event'])
  onInputOrPaste(event: Event): void {
    // Use setTimeout to ensure we get the updated value after paste
    setTimeout(() => {
      const inputElement = event.target as HTMLInputElement;
      const newValue = inputElement.value.toLowerCase();

      // Only update if the value has changed
      if (this.lastValue !== newValue) {
        const start = inputElement.selectionStart;
        const end = inputElement.selectionEnd;

        // Update the input value
        inputElement.value = newValue;

        // Try to restore the cursor position if supported
        this.trySetSelectionRange(inputElement, start, end);

        this.lastValue = newValue;

        // Trigger change detection and validators
        this.triggerInputEvent(inputElement);
      }
    }, 0);
  }

  private trySetSelectionRange(
    input: HTMLInputElement,
    start: number | null,
    end: number | null
  ): void {
    if (start !== null && end !== null) {
      try {
        input.setSelectionRange(start, end);
      } catch (e) {
        // setSelectionRange is not supported for this input type, do nothing
      }
    }
  }

  private triggerInputEvent(element: HTMLInputElement): void {
    const event = new Event('input', { bubbles: true, cancelable: true });
    element.dispatchEvent(event);
  }
}
