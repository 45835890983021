import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/ng-zorro-antd/form-field';
import { format as dateFormat, isValid } from 'date-fns';

interface DatepickerProps extends FormlyFieldProps {
  defaultValue?: string;
}

@Component({
  selector: 'app-nz-date-picker',
  template: `
    <veolia-input
      [disabled]="props.disabled!"
      [formlyAttributes]="field"
      [ngModel]="format(formControl.value)"
      (ngModelChange)="onChange($event)"
      placeholder="Datepicker"
      [size]="'default'"
      type="date"
      [variant]="'light'" />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormNZDatepickerComponent extends FieldType<
  FieldTypeConfig<DatepickerProps>
> {
  format(input: any): string {
    if (typeof input === 'string') {
      return input;
    }

    if (input instanceof Timestamp) {
      return dateFormat(input.toDate(), 'yyyy-MM-dd');
    }

    const date = new Date(input);
    if (isValid(date)) {
      return dateFormat(date, 'yyyy-MM-dd');
    }

    return String(input);
  }

  onChange(value: any) {
    this.formControl.markAsDirty();
    this.formControl.patchValue(value);
    this.formControl.markAsTouched();
  }
}
