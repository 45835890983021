import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Solution } from 'src/app/core/models/solution';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { Chapter } from 'src/app/core/types/chapter';
import { Article32FormStore } from './article-32-form.store';
import { TranslateService } from '@ngx-translate/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ScrollService } from 'src/app/core/services/scroll.service';
import { listArt32 } from 'src/app/core/forms/checkbox-options';
import { FormName } from 'src/app/core/enums/form-name';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { FormlyFormOptions } from '@ngx-formly/core';
import { Auth } from '@angular/fire/auth';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { SolutionService } from 'src/app/core/services/solution.service';
import SolutionApi from 'src/app/core/apis/solution.api';
import { serverTimestamp } from '@angular/fire/database';
import {
  Timestamp,
  serverTimestamp as fireServerTimestamp,
} from '@angular/fire/firestore';
import { LogService } from 'src/app/core/services/log.service';

@Component({
  selector: 'app-article-32-form',
  styleUrls: ['./article-32-form.component.scss'],
  templateUrl: './article-32-form.component.html',
  providers: [Article32FormStore],
})
export class Article32FormComponent implements OnInit {
  @Input() solutionId = '';
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;
  @Output() updateFormDate: EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }> = new EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }>();
  @Output() notifyDirty: EventEmitter<boolean> = new EventEmitter();

  formName: FormName = FormName.Article32;
  isDirty = false;
  isSaving = false;

  vm$ = this.article32FormStore.vm$;

  formChapters: string[] = [
    'AR32_00',
    'AR32_0',
    'AR32_1',
    'AR32_2',
    'AR32_3',
    'AR32_4',
    'AR32_5',
    'AR32_6',
    'AR32_7',
    'AR32_8',
  ];

  selectedChapter? = this.formChapters[0];

  fields: { [key: string]: Chapter } = {
    AR32_00: {
      assessable: false,
      label: 'AR32_00',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: {
                label: 'AR32_00_para1',
                assessable: false,
              },
              fieldGroup: [
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR32_00_para2')}</span><br /><br />`,
                },
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR32_00_para3')}</span><br /><br />`,
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_0: {
      evaluation: {
        commentField: 'AR32_0_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_0_RADIO',
      },
      label: 'AR32_0',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              fieldGroup: [
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR32_0_para')}</span><br /><br />
                  `,
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_1: {
      evaluation: {
        commentField: 'AR32_1_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_1_RADIO',
      },
      label: 'AR32_1',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: {
                label: '',
              },
              fieldGroup: [
                {
                  key: 'AR32_1_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_1_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_1_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_1_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_1_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_1_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_2: {
      evaluation: {
        commentField: 'AR32_2_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_2_RADIO',
      },
      label: 'AR32_2',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: { label: '' },
              fieldGroup: [
                {
                  key: 'AR32_2_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_2_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_2_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_2_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_2_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_2_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_2_03_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_2_03'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_2_03_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_2_04_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_2_04'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_2_04_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_3: {
      evaluation: {
        commentField: 'AR32_3_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_3_RADIO',
      },
      label: 'AR32_3',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: { label: '' },
              fieldGroup: [
                {
                  key: 'AR32_3_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_03_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_03'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_03_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_04_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_04'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_04_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_05_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_05'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_05_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_06_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_06'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_06_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_07_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_07'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_07_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_08_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_3_08'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_3_08_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_4: {
      evaluation: {
        commentField: 'AR32_4_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_4_RADIO',
      },
      label: 'AR32_4',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: { label: '' },
              fieldGroup: [
                {
                  key: 'AR32_4_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_03_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_03'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_03_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_04_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_04'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_04_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_05_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_05'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_05_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_06_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_06'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_06_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_07_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_07'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_07_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_08_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_08'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_08_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_09_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_09'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_09_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_10_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_10'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_10_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_11_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_11'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_11_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_12_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_12'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_12_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_13_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_13'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_13_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_14_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_4_14'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_4_14_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_5: {
      evaluation: {
        commentField: 'AR32_5_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_5_RADIO',
      },
      label: 'AR32_5',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: { label: '' },
              fieldGroup: [
                {
                  key: 'AR32_5_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_5_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_5_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_03_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_5_03'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_03_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_04_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_5_04'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_04_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_05_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_5_05'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_5_05_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_6: {
      evaluation: {
        commentField: 'AR32_6_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_6_RADIO',
      },
      label: 'AR32_6',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: { label: '' },
              fieldGroup: [
                {
                  key: 'AR32_6_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_6_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_6_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_6_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_6_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_6_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_6_03_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_6_03'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_6_03_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_6_04_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_6_04'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_6_04_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_7: {
      evaluation: {
        commentField: 'AR32_7_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_7_RADIO',
      },
      label: 'AR32_7',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: { label: '' },
              fieldGroup: [
                {
                  key: 'AR32_7_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_7_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_7_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_7_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_7_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_7_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_7_03_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_7_03'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_7_03_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_7_04_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_7_04'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_7_04_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    AR32_8: {
      evaluation: {
        commentField: 'AR32_8_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR32_8_RADIO',
      },
      label: 'AR32_8',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: { label: '' },
              fieldGroup: [
                {
                  key: 'AR32_8_01_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_8_01'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_8_01_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_8_02_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_8_02'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_8_02_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_8_03_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_8_03'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_8_03_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_8_04_SELECT',
                  type: 'select',
                  props: {
                    label: this.translateService.instant('AR32_8_04'),
                    options: listArt32,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR32_8_04_PROOF',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant('Control item'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  };

  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };
  formData: any = {};

  constructor(
    private auth: Auth,
    private article32FormService: Article32FormService,
    private article32FormStore: Article32FormStore,
    private logService: LogService,
    public messageService: NzMessageService,
    public solutionApi: SolutionApi,
    public solutionService: SolutionService,
    public scrollService: ScrollService,
    private translateService: TranslateService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    this.article32FormStore.resetData();
    this.article32FormStore.setSolutionId(this.solutionId);
    this.article32FormStore.setSolution(this.solution!);
    this.article32FormStore.setBusinessUnit(this.businessUnit!);
    this.article32FormStore.setFormName('Article 32 Form');
    this.article32FormStore.getArticle32Form();
    this.article32FormStore.getEvaluation();
    this.article32FormStore.getAllReservations();
    this.article32FormStore.getAllComments();
    this.article32FormStore.getAllFiles();
    this.article32FormStore.getStatuses();

    this.options.formState.disabled = !this.isVendor || this.isVendorReadOnly;

    this.article32FormStore.form$.subscribe(data => {
      this.updateFormDate.emit({
        formName: this.formName,
        lastUpdate: data?.lastUpdate!,
        lastUpdateBy: data?.lastUpdateBy!,
        status: data?.article32Status!,
      });
    });
  }

  set dirty(isDirty: boolean) {
    this.isDirty = isDirty;
    this.notifyDirty.emit(isDirty);
  }

  selectChapter(id: string) {
    this.selectedChapter = id;
    this.scrollService.scrollToElementById(id);
  }

  formEdited(data: any) {
    this.formData = { ...this.formData, ...data };
    this.dirty = true;
  }

  editChapter(data: any) {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const messageId = this.messageService.loading('Saving...', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      //if the form has been already assessed, the status for the form stay evaluated in 'solutions' and in 'article32Form' and in rtdb
      if (
        this.solution?.article32Status === 'Recommended' ||
        this.solution?.article32Status === 'Reservations' ||
        this.solution?.article32Status === 'Not recommended'
      ) {
        this.article32FormService
          .update(
            this.solutionId,
            {
              ...data,
            },
            true
          )
          .then(() => {
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
            });
          })
          .then(() => {
            this.isSaving = false;
            this.dirty = false;
            this.messageService.remove(messageId);
            this.veoliaMessageService.create(
              {
                title: 'Saved successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            this.isSaving = false;
            console.log(error);
            this.messageService.remove(messageId);
          });
      } else {
        //if the form has not been assessed

        this.article32FormService
          .update(
            this.solutionId,
            {
              ...data,
              article32Status: 'Vendor is responding',
            },
            true
          )
          .then(() => {
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
              article32Status: 'Vendor is responding',
              status: 'Ongoing',
            });
          })
          .then(() => {
            this.isSaving = false;
            this.dirty = false;
            this.messageService.remove(messageId);
            this.veoliaMessageService.create(
              {
                title: 'Saved successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            console.log(error);
            this.isSaving = false;
            this.messageService.remove(messageId);
          });
      }
    }, 2000);
  }

  saveAll() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const messageId = this.messageService.loading('Saving All', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      //if the form has been already assessed, the status for the form stay evaluated in 'solutions' and in 'article32Form' and in rtdb
      if (
        this.solution?.article32Status === 'Recommended' ||
        this.solution?.article32Status === 'Reservations' ||
        this.solution?.article32Status === 'Not recommended'
      ) {
        console.log(
          'FORM has been already evaluated',
          this.solution?.article32Status
        );

        this.article32FormService
          .update(
            this.solutionId,
            {
              ...this.formData,
            },
            true
          )
          .then(() => {
            this.dirty = false;
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
            });
          })
          .then(() => {
            this.dirty = false;
            this.isSaving = false;
            this.messageService.remove(messageId);
            this.logService.setLogForAction(
              'SaveForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );
            this.veoliaMessageService.create(
              {
                title: 'Saved All successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            this.isSaving = false;
            this.dirty = true;
            console.log(error);
            this.messageService.remove(messageId);
          });
      } else {
        //if the form has not been assessed

        this.article32FormService
          .update(
            this.solutionId,
            {
              ...this.formData,
              article32Status: 'Vendor is responding',
            },
            true
          )
          .then(() => {
            this.dirty = false;
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
              article32Status: 'Vendor is responding',
              status: 'Ongoing',
            });
          })
          .then(() => {
            this.isSaving = false;
            this.messageService.remove(messageId);
            this.logService.setLogForAction(
              'SaveForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );
            this.veoliaMessageService.create(
              {
                title: 'Saved All successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            console.log(error);
            this.isSaving = false;
            this.dirty = true;
            this.messageService.remove(messageId);
          });
      }
    }, 2000);
  }

  markAsComplete(status: boolean) {
    const messageId = this.messageService.loading('Marking as completed', {
      nzDuration: 0,
    }).messageId;

    setTimeout(() => {
      this.article32FormService
        .update(
          this.solutionId,
          {
            article32Status: status ? 'Vendor is responding' : 'Completed',
          },
          true
        )
        .then(() => {
          const data: any = {
            article32Status: status ? 'Vendor is responding' : 'Completed',
          };

          if (status === false) {
            data['status'] = 'Ongoing';
          }

          return this.solutionService.update(this.solutionId, data, true);
        })
        .then(() => {
          this.messageService.remove(messageId);

          if (status == false) {
            this.veoliaMessageService.create(
              {
                title: 'Marked as completed successfully',
              },
              { duration: 3000 }
            );

            this.logService.setLogForAction(
              'SaveCompleteForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save complete form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );

            this.solutionApi
              .notifySolutionFormCompleted(this.solutionId, {
                formName: this.formName,
                formNameRoute: 'article32Form',
              })
              .subscribe();
          } else {
            this.veoliaMessageService.create(
              {
                title: 'Marked as uncompleted successfully',
              },
              { duration: 3000 }
            );
          }

          this.refreshForm();
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  get formCompletionStatus() {
    if ((this.article32FormStore.getForm() as Article32Form)?.article32Status) {
      if (
        ['New', 'Vendor is responding'].includes(
          (this.article32FormStore.getForm() as Article32Form)?.article32Status
        )
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  get formStatus() {
    return (
      (this.article32FormStore.getForm() as Article32Form)?.article32Status ||
      'New'
    );
  }

  get isVendor() {
    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    return (
      this.solution?.emailVendorReadonlyArt32?.includes(
        this.auth.currentUser?.email!
      ) || false
    );
  }

  refreshForm() {
    this.article32FormStore.getArticle32Form();
    this.article32FormStore.getEvaluation();
    this.article32FormStore.getAllReservations();
  }
}
