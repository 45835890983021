import { Injectable } from '@angular/core';
import { BaseApi } from './api';
import { Observable } from 'rxjs';
import { ChangeSolutionOwnerDTO } from '../dtos/change-solution-owner.dto';
import { observe } from '@ngx-formly/core/lib/utils';

@Injectable({
  providedIn: 'root',
})
class SolutionApi extends BaseApi {
  getSolutionById(id: string): Observable<any> {
    return this.get(`/api/solutions/${id}`);
  }

  getSolutionBusinessUnitById(id: string): Observable<any> {
    return this.get(`/api/solutions/business_unit/${id}`);
  }

  changeSolutionOwner(data: ChangeSolutionOwnerDTO): Observable<any> {
    return this.post('/api/admin/change_solution_owner', data);
  }

  updateAccessRight(
    solutionId: string,
    email: string,
    form: string,
    value: number
  ): Observable<any> {
    return this.post('/api/admin/update_solution_access', {
      solutionId,
      email,
      form,
      value,
    });
  }

  removeUserFromSolution(
    solutionId: string,
    email: string,
    type: string
  ): Observable<any> {
    return this.post('/api/admin/remove_solution_user', {
      solutionId,
      email,
      type,
    });
  }

  notifySolutionCreated(id: string): Observable<any> {
    return this.get(`/api/solutions/${id}/notify_solution_created`);
  }

  notifySolutionCanceled(id: string): Observable<any> {
    return this.get(`/api/solutions/${id}/notify_solution_canceled`);
  }

  notifySolutionValidated(id: string): Observable<any> {
    return this.get(`/api/solutions/${id}/notify_solution_validated`);
  }

  notifySolutionFormCompleted(id: string, data: any) {
    return this.post(`api/solutions/${id}/notify_form_completed`, data);
  }

  exportSolutions(solutionType: string, status: string) {
    return this.get(
      `api/admin/solutions/export`,
      { status, solutionType },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  exportSolutionsEvalutor(
    solutionType: string,
    status: string,
    businessUnits: string[]
  ) {
    return this.get(
      'api/evaluator/solutions/export',
      { solutionType, status, businessUnits },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
}

export default SolutionApi;
