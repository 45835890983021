import { Component, Input } from '@angular/core';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import SolutionApi from 'src/app/core/apis/solution.api';
import { Solution } from 'src/app/core/models/solution';
import { ConvertToCSV } from 'src/app/core/utils/csv';

@Component({
  standalone: true,
  selector: 'app-export-csv-button',
  templateUrl: './export-csv-button.component.html',
  styleUrls: ['./export-csv-button.component.scss'],
  imports: [VeoliaButtonComponent],
})
export class ExportCSVButtonComponent {
  @Input() exportType = '';
  @Input() isSearching = false;
  @Input() solutionType = 'Saas';
  @Input() solutions?: Solution[];
  @Input() userType: 'admin' | 'evaluator' = 'admin';
  @Input() businessUnits: string[] = [];
  @Input() loading = false;

  constructor(private solutionApi: SolutionApi) {}

  export() {
    if (!this.isSearching) {
      if (this.userType === 'admin') {
        this.solutionApi
          .exportSolutions(this.solutionType, this.exportType)
          .subscribe({
            next: response => {
              const blob = new Blob([response.body], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download =
                this.fileNamePrefix + this.formatDate(new Date()) + '.csv';
              link.click();
              window.URL.revokeObjectURL(url);
            },
            error: error => {
              console.error('Error downloading file:', error);
            },
          });
      } else {
        this.solutionApi
          .exportSolutionsEvalutor(
            this.solutionType,
            this.exportType,
            this.businessUnits
          )
          .subscribe({
            next: response => {
              const blob = new Blob([response.body], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download =
                this.fileNamePrefix + this.formatDate(new Date()) + '.csv';
              link.click();
              window.URL.revokeObjectURL(url);
            },
            error: error => {
              console.error('Error downloading file:', error);
            },
          });
      }
    } else {
      console.log('export search');
      console.log(this.solutions?.[0]);
      this.downloadFile();
    }
  }

  get fileNamePrefix() {
    let filename_prefix = 'All SaaS solutions-';

    if (this.exportType === 'pending') {
      filename_prefix = 'All SaaS pending solutions-';
    }

    if (this.exportType === 'evaluated') {
      filename_prefix = 'All SaaS evaluated solutions-';
    }

    if (this.exportType === 'evaluate') {
      filename_prefix = 'All SaaS evaluate solutions-';
    }

    return filename_prefix;
  }

  formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  downloadFile() {
    const csvData = ConvertToCSV(this.solutions!);
    console.log(csvData);
    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute(
      'download',
      this.fileNamePrefix + '-solutions' + '.csv'
    );
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
