<ng-container *ngIf="vm$ | async as vm">
  <nz-alert
    *ngIf="vm.error"
    nzType="error"
    [nzMessage]="vm.error"
    nzShowIcon></nz-alert>
  <div>
    <div class="row row-right row-vertical-center">
      <app-export-csv-button
        exportType="evaluate"
        [solutions]="vm.solutions!"
        [isSearching]="vm.isSearching"
        [solutionType]="vm.solutionType === 0 ? 'SaaS' : 'SaaS'"
        userType="evaluator"
        [businessUnits]="vm.evaluatorBusinessUnits"
        [loading]="vm.isLoading" />
      <search-input
        [isSearching]="vm.isSearching"
        (onChange)="onSearch($event)"></search-input>
    </div>
    <simple-spinner
      style="text-align: center !important"
      *ngIf="vm.isLoading"></simple-spinner>
    <app-solutions-table
      [actions]="['edit', 'share', 'summary', 'logs', 'access', 'ssc']"
      [solutions]="vm.solutions"
      [solutionType]="vm.solutionType"
      (refresh)="onRefreshAfterEmit()!"
      [isSearching]="vm.isSearching"
      (sorted)="tableSorted($event)"></app-solutions-table>
  </div>
  <app-pagination
    *ngIf="!vm.isSearching || vm.search === ''"
    [loading]="vm.isLoading || vm.isSearching"
    [size]="vm.limit"
    [page]="vm.currentPage"
    [total]="vm.total"
    (onPageChange)="changePage($event)"
    (onSizeChange)="changeSize($event)"></app-pagination>
</ng-container>
